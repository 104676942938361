<!--
 * @Description: 公众号 意见反馈 adviseFeedBack
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-01 13:34:01
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="反馈建议" />
    <van-row class="top">
      <van-button round
                  class="myfeedback"
                  style="margin-left:71%;margin-top:7px"
                  @click="toMyFeedBack">
        <van-image width="14"
                   height="12"
                   style="margin-right:4px;"
                   :src="require('@/assets/wxAdviseFeedBack/myFeedBack.png')" />我要反馈
      </van-button>

    </van-row>
    <van-row class="list-box">
      <van-list class="list"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                :immediate-check="false"
                @load="getProblemFeedBackList">
        <van-row class="down"
                 v-for="(item,index) in problemFeedBackList"
                 :key="index"
                 @click="feedBackDetails(item)">
          <van-row class="lineone style">
            <van-col span="6">{{item.feedbackTypeDesc}}
            </van-col>
            <van-col span="8">
              <van-row class="button"
                       v-if="item.feedbackStatusCode==1"
                       style="color: #19A9FC;border-color:#19A9FC">{{item.feedbackStatusDesc}}</van-row>
              <van-row class="button"
                       v-else>{{item.feedbackStatusDesc}}</van-row>
            </van-col>

            <van-col span="10"
                     style="font-size:12px;color:#999999;">{{item.createTime}}</van-col>
          </van-row>
          <van-row class="linetwo style">
            <van-row style="font-size:15px;color:#666666">反馈描述 ：
            </van-row>
            <van-row style="font-size:15px;color:#666666">
              {{item.content}}</van-row>
          </van-row>
        </van-row>
      </van-list>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import getUserInfo from '@/utils/getUserInfo.js'
export default {
  // import引入的组件需要注入到对象中才能使用

  components: {},
  data () {
    // 这里存放数据
    return {
      // 建议列表
      problemFeedBackList: [],
      // 分页加载
      loading: false,
      finished: false,
      error: false,
      total: '',
      pageNum: 0,
      pageSize: 10
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    getUserInfo().then(() => {
      this.getProblemFeedBackList()
    })
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取反馈建议记录
    getProblemFeedBackList () {
      this.pageNum++
      const info = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.$adviseFeedBack.queryFeedBackList(info).then(res => {
        this.problemFeedBackList = [...this.problemFeedBackList, ...res.resultEntity.list]
        this.total = res.resultEntity.total
        if (this.problemFeedBackList.length >= this.total) {
          this.finished = true
          this.loading = false
        } else {
          this.finished = false
          this.loading = false
        }
      }).catch(err => {
        console.log('获取失败', err)
        this.error = true
        this.loading = false
        this.pageNum--
      })
    },
    // 跳转到我要反馈
    toMyFeedBack () {
      this.$router.push('/myFeedBack')
    },
    // 跳转到反馈详情
    feedBackDetails (item) {
      this.$router.push({ name: 'feedBackDetails', query: { feedBackDetails: item } })
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.style {
  margin: 12px;
}
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .top {
    height: 44px;
    background-color: #ffffff;
    /deep/.van-button--normal {
      padding: 0 !important;
    }
    .myfeedback {
      height: 30px;
      width: 90px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(96, 96, 96, 0.16);
      border-radius: 30px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      /deep/.van-image__img,
      .van-image__error,
      .van-image__loading {
        margin-top: 2px !important;
      }
    }
  }
  .down {
    background-color: #ffffff;
    width: 93.6%;
    margin: 12px 3.2% 0 3.2%;
    border-radius: 5px;
    .list-box {
      height: calc(100vh - 94px);
      width: 100%;
      overflow: auto;
      .list {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .lineone {
      border-bottom: 1px solid #ebebeb;
      height: 41px;
      line-height: 41px;
      .button {
        border: 1px solid #999999;
        border-radius: 15px;
        height: 17px;
        line-height: 17px;
        width: 44px;
        font-size: 10px;
        margin-top: 12px;
        text-align: center;
        color: #999999;
      }
    }
    .linetwo .van-row {
      padding-top: 5px;
    }
  }
}
</style>
